import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { formatDuration } from 'utils/Helpers';
import { Text, Button, FlatButton, ResultCompact, OutlineButton } from '@reservamos/elements';
import TimeIcon from 'ui/atoms/TimeIcon';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelTheme from 'hooks/whitelabel/useWhitelabelTheme';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import '../../ui/layouts/MatrixResult';
import PreviousPrice from 'ui/atoms/PreviousPrice';
import TripSchedule from 'ui/atoms/TripSchedule';
import { isSeatsOnResultActivated } from 'utils/seats';
import DotersFlatfareBadge from 'ui/atoms/DotersFlatfareBadge';
import TripFiltersBadges from './TripFiltersBadges';
import NewPriceComponent from './NewPriceComponent';
import TripDiscountAdvice from './TripDiscountAdvice';
import RouteDetailsModal from '../../ui/molecules/RouteDetailsModal';
import ResultCategoriesAvailability from './ResultCategoriesAvailability';

/**
 * This result is used by:
 * - GFA (Primera Plus, Roll & Bits)
 * - Rápido Ochoa
 * - Berlinas del Fonce
 * - Expresso Sao Luiz
 * - Cruz del Sur
 */

// hide the duration if is minor than 16min
// because this is probably wrong estimation
const MIN_DURATION = 15;

/**
 * Component for displaying a discount advice. Could be a discount badge or a flat fare badge.
 * @param {Object} params - The component props.
 * @param {boolean} params.showDiscount - Flag indicating if the discount should be shown.
 * @param {boolean} params.showFlatFare - Flag indicating if the flat fare should be shown.
 * @param {string} params.discountType - The type of discount.
 * @param {number} params.discountAvailability - The availability of the discount.
 * @param {number} params.discountAmount - The discount amount.
 * @param {number} [params.discountPercent] - The discount percentage.
 * @param {number} params.total - The total price of the trip.
 * @param {string} params.way - The direction of the trip.
 * @returns {JSX.Element | null} The rendered component.
 */
const DiscountAdvice = ({
  showDiscount,
  showFlatFare,
  discountType,
  discountAvailability,
  discountAmount,
  discountPercent,
  total,
  way,
}) => {
  if (!showDiscount) return null;

  if (showFlatFare) {
    return <DotersFlatfareBadge />;
  }
  return (
    <TripDiscountAdvice
      discountType={discountType}
      discountAvailability={discountAvailability}
      discountAmount={discountAmount}
      discountPercent={discountPercent}
      tripTotal={total}
      way={way}
    />
  );
};

/**
 * Component for displaying a new result in GFA format.
 *
 * @param {Object} props - The component props.
 * @param {String} props.id - Trip Id.
 * @param {Date} props.arrival - The arrival date.
 * @param {Date} props.departure - The departure date.
 * @param {number} props.duration - The duration of the trip.
 * @param {function} props.onSelectClick - The function to handle the selection of the trip.
 * @param {Object} props.providerDiscount - The discount information for the provider.
 * @param {number} props.total - The total price of the trip.
 * @param {Object} props.trip - The trip details.
 * @param {boolean} props.highLightType - Flag indicating if the result should be highlighted.
 * @param {string} props.highlightText - The text to highlight.
 * @param {number} props.availability - The availability of seats.
 * @param {boolean} props.isExchange - Flag indicating if it's an exchange trip.
 * @param {string} props.way - The direction of the trip.
 * @param {Array} props.filtersApplied - The filters applied to the trip.
 * @param {boolean} props.showWalletPoints - Flag indicating if wallet points should be shown.
 * @param {number} props.walletPoints - The wallet points available.
 * @param {boolean} props.useFlatFare - Flag indicating if flat fare should be shown.
 * @param {Object} props.pricing - The pricing information for the trip.
 * @param {string} props.walletType - The type of wallet.
 * @param {string} props.serviceType - The type of service.
 * @param {boolean} props.highLightAsMobile - Flag indicating if the result should be highlighted as mobile.
 * @param {Array} props.availableCategories - The available categories for the trip.
 * @param {boolean} props.supportWoman - Flag indicating if
 * @param {boolean} props.showNewFiltersList - Flag indicating if the new filters list should be shown.
 * @param {number} props.totalBeforeDiscount - The total price of the trip before discount.
 * @param {node} props.children - The children to render.
 * @param {boolean} props.isOpen - Flag indicating if the result is open.
 * @param {boolean} props.recentView - Flag indicating if the result is in the recent view.
 */
const SimpleTripResult = ({
  id,
  arrival,
  departure,
  duration,
  onSelectClick,
  providerDiscount,
  total,
  trip,
  highLightType,
  highlightText,
  availability,
  isExchange,
  way,
  filtersApplied,
  showWalletPoints,
  walletPoints,
  useFlatFare,
  pricing,
  walletType,
  serviceType,
  highLightAsMobile,
  availableCategories,
  supportWoman,
  showNewFiltersList,
  totalBeforeDiscount,
  children,
  isOpen,
  recentView,
  destination,
  origin,
}) => {
  const [showDetailsModal, setShowDetailsModal] = useState(true);
  const environment = useWhitelabelEnvs();
  const features = useWhitelabelFeatures();
  const { getServiceType, serviceTypes, resultsCTAColor } = useWhitelabelTheme();
  const { t } = useTranslation();
  const { line, variableDepartureTime, stops, service, seatsRecline } = trip;
  const showDuration = duration > MIN_DURATION;

  const stopsText = t('search:trip_stopovers', {
    count: stops,
    context: stops === 0 ? 'direct' : null,
  });
  /**
   * Handles the selection of a trip item, showing the bus diagram if applicable.
   */
  const handleOnSelect = (funnelSection) => {
    const funnelSectionSelected = funnelSection === 'details' ? 'details' : 'results';
    onSelectClick(funnelSectionSelected);
    if (isExchange) setShowDetailsModal(false);
  };

  const availableSeat = availability;

  // TODO: Remove this when the seat on results experiment is finished
  const isCDS = environment.brand === 'cds';
  const ctaText = isSeatsOnResultActivated() ? t('general:view_seats') : t('general:select');

  const buttonText = isOpen ? t('general:close') : ctaText;
  const departureHour = moment(departure).format('LT');
  const arrivalHour = moment(arrival).format('LT');

  /**
   * Method to handle the selection of a trip
   * @param {String} funnelSection - section of the funnel where the trip was selected
   */

  const showFlatFare = useFlatFare === 'valid';

  const { useLineNameAsServiceType = false } = environment.theme.serviceTypes || {};

  const serviceProp = serviceTypes ? service : serviceType;
  const serviceTypeLabel = useLineNameAsServiceType ? line.name : getServiceType(serviceProp).label;

  const detailsComponent = showDetailsModal ? (
    <RouteDetailsModal tripId={id} trip={trip} provider={line}>
      <div className="matrix-mobile-click">
        <FlatButton type="accent" weight="bold" size="S">
          {t('general:show_details_new')}
        </FlatButton>
      </div>
    </RouteDetailsModal>
  ) : (
    <></>
  );
  const hidePriceOnExchange = features.HIDE_PRICE_ON_EXCHANGE && isExchange;

  const transportTypeImageSrc = environment.theme.transportTypes
    ? environment.theme.transportTypes[trip.diagramType]?.image
    : null;
  const providerLogoSrc = transportTypeImageSrc || line.logoUrl;

  let brandToShow = features.SHOW_SERVICE_TYPE_BADGE ? serviceTypeLabel : line.name;
  if (!features.SHOW_RESULT_LINE_NAME) brandToShow = '';

  const hasProviderDiscount = providerDiscount && Boolean(providerDiscount.amount);
  const showPriceWithoutDiscount = hasProviderDiscount && features.SHOW_OFFICE_PRICE;
  const showTerminals = features.SHOW_TERMINALS_ON_RESULTS;

  return (
    <ResultCompact
      recentViewText={t('trips:recently_seen')}
      recentView={recentView}
      isOpen={isOpen}
      brand={brandToShow}
      logoUrl={providerLogoSrc}
      iconDeparture={<TimeIcon time={departure} />}
      duration={
        showDuration && (
          <Text size="S" mediumSize="M" mobileSize="XS" color="grayMedium">
            {formatDuration(duration)}
            {isCDS && <>{` · ${seatsRecline}`}</>}
          </Text>
        )
      }
      discount={
        <DiscountAdvice
          showDiscount={!hidePriceOnExchange}
          showFlatFare={showFlatFare}
          discountType={pricing?.discountType}
          discountAvailability={pricing?.discountAvailability}
          discountAmount={providerDiscount?.amount}
          discountPercent={providerDiscount?.percent}
          way={way}
          total={total}
        />
      }
      seats={<Text size="S">{t('search:seat', { count: availableSeat })}</Text>}
      filter={
        showNewFiltersList ? (
          <TripFiltersBadges filtersApplied={filtersApplied} trip={trip} minimalDesign />
        ) : (
          <>
            {isCDS ? (
              <>{detailsComponent}</>
            ) : (
              <ResultCategoriesAvailability
                availableCategories={availableCategories}
                supportWoman={supportWoman}
              />
            )}
          </>
        )
      }
      tripType={
        features.SHOW_VARIABLE_DEPARTURE_TIME && (
          <Text size="S">
            {t('search:trip_service', {
              context: variableDepartureTime ? 'on_the_way' : 'local',
            })}
          </Text>
        )
      }
      schedule={
        <TripSchedule
          departureHour={departureHour}
          arrivalHour={arrivalHour}
          mainContent={stopsText}
          mobileSize="L"
          departureTerminal={isCDS && origin.name}
          arrivalTerminal={isCDS && destination.name}
          showTerminals={showTerminals}
        />
      }
      details={detailsComponent}
      button={
        highLightType ? (
          <Button
            text={buttonText}
            onClick={handleOnSelect}
            padding="L"
            size="M"
            weight="semibold"
            variant={resultsCTAColor}
            isRounded
          />
        ) : (
          <OutlineButton
            isRounded
            variant={resultsCTAColor}
            text={buttonText}
            onClick={handleOnSelect}
          />
        )
      }
      price={
        !hidePriceOnExchange ? (
          <NewPriceComponent
            total={total}
            walletTotal={walletPoints}
            highLightType={highLightType}
            walletType={walletType}
            showWalletTotal={showWalletPoints}
            secondaryPrice={
              showPriceWithoutDiscount ? <PreviousPrice price={totalBeforeDiscount} /> : null
            }
            lighterText={isOpen}
          />
        ) : null
      }
      changeLayout={features.CHANGE_RESULT_LAYOUT}
      variantShadow={features.RESULT_SHADOW_VARIANT}
      highlight={highLightType}
      mobileLayout={highLightAsMobile}
      highlightMinHeight={highLightAsMobile}
      highlightText={highlightText}
    >
      {children}
    </ResultCompact>
  );
};

const placeType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  cityName: PropTypes.string.isRequired,
});

SimpleTripResult.propTypes = {
  id: PropTypes.string.isRequired,
  arrival: PropTypes.string.isRequired,
  departure: PropTypes.string.isRequired,
  destination: placeType.isRequired,
  duration: PropTypes.number.isRequired,
  onSelectClick: PropTypes.func.isRequired,
  origin: placeType.isRequired,
  providerDiscount: PropTypes.any,
  availability: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  trip: PropTypes.shape({
    line: PropTypes.shape({
      logoUrl: PropTypes.string.isRequired,
      services: PropTypes.arrayOf(PropTypes.string).isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    pricing: PropTypes.shape({
      discountType: PropTypes.string,
      discountAvailability: PropTypes.number,
    }),
    passengerTypes: PropTypes.array,
    variableDepartureTime: PropTypes.bool,
    secondFloor: PropTypes.bool,
    stops: PropTypes.number,
    service: PropTypes.string,
    diagramType: PropTypes.string,
    seatsRecline: PropTypes.bool,
  }).isRequired,
  variableDepartureTime: PropTypes.bool,
  isExchange: PropTypes.bool,
  highLightType: PropTypes.bool,
  way: PropTypes.string,
  filtersApplied: PropTypes.object,
  showWalletPoints: PropTypes.string,
  walletPoints: PropTypes.number,
  useFlatFare: PropTypes.bool,
  pricing: PropTypes.shape({
    discountType: PropTypes.string,
    discountAvailability: PropTypes.number,
  }),
  walletType: PropTypes.string,
  serviceType: PropTypes.string.isRequired,
  highLightAsMobile: PropTypes.bool,
  availableCategories: PropTypes.array,
  supportWoman: PropTypes.bool,
  showNewFiltersList: PropTypes.bool,
  totalBeforeDiscount: PropTypes.number,
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  recentView: PropTypes.bool,
  highlightText: PropTypes.string,
};

SimpleTripResult.defaultProps = {
  providerDiscount: null,
};

DiscountAdvice.propTypes = {
  showDiscount: PropTypes.bool.isRequired,
  showFlatFare: PropTypes.bool.isRequired,
  discountType: PropTypes.string,
  discountAvailability: PropTypes.number,
  discountAmount: PropTypes.number,
  discountPercent: PropTypes.number,
  total: PropTypes.number.isRequired,
  way: PropTypes.string.isRequired,
};

export default SimpleTripResult;

import enUS from './en-US';
import esMX from './es-MX';
import esCO from './es-CO';
import ptBR from './pt-BR';
import esPE from './es-PE';
import frFR from './fr-FR';

export default {
  'es-MX': esMX,
  'es-CO': esCO,
  'en-US': enUS,
  'pt-BR': ptBR,
  'es-PE': esPE,
  'fr-FR': frFR,
};

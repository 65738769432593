import { required, phone, email, taxpayerId, legalName } from './formValidations';

const purchaserValidations = {
  purchaserFirstName: required,
  purchaserLastName: required,
  email: [required, email],
  phone: [required, phone],
  phoneCountry: [required],
  legalTerms: required,
  taxpayerId,
  legalName,
};

export default purchaserValidations;

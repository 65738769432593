/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import PurchaserForm from './PurchaserForm';
import 'styles/components/purchase/PaymentOptions';
import PaymentOptionsType from './PaymentOptionsType';

const PaymentOptions = (props) => {
  const { features } = useSelector((state) => state.whitelabelConfig);
  const { submitPurchaser, isExchange, removeTopMargin, hidePurchaserForm = false } = props;

  const SHOW_PURCHASER_FORM = features.PURCHASER_FORM_ON === 'CHECKOUT';
  const SHOW_INVOICE_TOGGLE = true; // TODO: Add this variable to backend

  return (
    <div className={removeTopMargin ? 'payment-content remove-top-margin' : ''}>
      {SHOW_PURCHASER_FORM && !hidePurchaserForm && (
        <PurchaserForm
          onSubmit={submitPurchaser}
          showPurchaserForm={SHOW_PURCHASER_FORM}
          isExchange={isExchange}
          showInvoiceToggle={SHOW_INVOICE_TOGGLE}
        />
      )}

      <PaymentOptionsType {...props} />
    </div>
  );
};

PaymentOptions.propTypes = {
  availablePayments: PropTypes.array.isRequired,
  onPaymentTypeChange: PropTypes.func.isRequired,
  submitPurchaser: PropTypes.func.isRequired,
  isOpenTicket: PropTypes.bool.isRequired,
  selectedOption: PropTypes.string.isRequired,
  cardErrorOccured: PropTypes.bool,
  initialPurchaserValues: PropTypes.object,
  isExchange: PropTypes.bool,
  removeTopMargin: PropTypes.bool,
  hidePurchaserForm: PropTypes.bool,
};

export default PaymentOptions;

import { Icon, Text } from '@reservamos/elements';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

function MinorCompleteDisclaimer() {
  const { t } = useTranslation('purchase');
  const { brand } = useWhitelabelEnvs();

  return (
    <div className="bg-yellow bg-opacity-50 p-2 flex gap-1">
      <Icon type="Warning" color="warning" size="S" />
      <Trans
        t={t}
        tOptions={{
          context: brand,
        }}
        i18nKey="text.minor_disclaimer"
        // context={brand}
        components={{
          text: <Text>placeholder</Text>,
          cta: (
            <a
              className="font-semibold cursor-pointer hover:underline size-sm"
              href="https://google.com"
              target="_blank"
            >
              placeholder
            </a>
          ),
        }}
      />
    </div>
  );
}

export default MinorCompleteDisclaimer;

// Global, no API involved
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const CHANGE_MENU = 'CHANGE_MENU';
export const TOGGLE_PURCHASE_REVIEW_OVERLAY = 'TOGGLE_PURCHASE_REVIEW_OVERLAY';
export const SET_LODGING_PROVIDER = 'SET_LODGING_PROVIDER';
export const UA_FORM_ERRORS = 'UA_FORM_ERRORS';

// Config from API
export const SET_CONFIG = 'SET_CONFIG';
export const SET_LOYALTY_PROGRAM = 'SET_LOYALTY_PROGRAM';

// Collections
export const SET_CITIES = 'SET_CITIES';
export const SET_TERMINALS = 'SET_TERMINALS';
export const SET_AIRPORTS = 'SET_AIRPORTS';
export const SET_LINES = 'SET_LINES';
export const SET_CARRIERS = 'SET_CARRIERS';

// Search
export const SET_SEARCH_PARAMS = 'SET_SEARCH_PARAMS';
export const REQUEST_SEARCH = 'REQUEST_SEARCH';
export const RECEIVE_SEARCH = 'RECEIVE_SEARCH';
export const INVALIDATE_SEARCH = 'INVALIDATE_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';
export const FETCHING_COUPON = 'FETCHING_COUPON';
export const SAVE_COUPON_CODE = 'SAVE_COUPON_CODE';
export const DELETE_COUPON_CODE = 'DELETE_COUPON_CODE';
export const RESET_COUPON_FORM_STATE = 'RESET_COUPON_FORM_STATE';
export const SET_DISCOUNTED_TRIPS = 'SET_DISCOUNTED_TRIPS';
export const RECEIVE_MIN_PRICES = 'RECEIVE_MIN_PRICES';
export const RECEIVE_NEAR_TERMINALS = 'RECEIVE_NEAR_TERMINALS';
export const SET_DYNAMIC_ATTRIBUTES = 'SET_DYNAMIC_ATTRIBUTES';
export const DELETE_WALLET_DISCOUNT = 'DELETE_WALLET_DISCOUNT';
export const FETCHING_WALLET_DISCOUNT = 'FETCHING_WALLET_DISCOUNT';
export const SET_RESULT_PRICE_TO_SHOW = 'SET_RESULT_PRICE_TO_SHOW';

export const SET_REDIRECT_PARAMS = 'SET_REDIRECT_PARAMS';

// Providers
export const SET_PROVIDERS = 'SET_PROVIDERS';
export const SET_TRANSPORT_STATE = 'SET_TRANSPORT_STATE';
export const FILTER_PROVIDERS = 'FILTER_PROVIDERS';
export const SET_PROVIDERS_SORT_ORDER = 'SET_PROVIDERS_SORT_ORDER';
export const SET_PROVIDERS_FILTER_BY = 'SET_PROVIDERS_FILTER_BY';
export const RESET_PROVIDERS = 'RESET_PROVIDERS';

// Trips
export const SETUP_TRIPS = 'SETUP_TRIPS';
export const RECEIVE_BUSES = 'RECEIVE_BUSES';
export const RECEIVE_FLIGHTS = 'RECEIVE_FLIGHTS';
export const RECEIVE_MIXED = 'RECEIVE_MIXED';
export const SET_TRIP_SORT_ORDER = 'SET_TRIP_SORT_ORDER';
export const SET_TRIP_FILTER = 'SET_TRIP_FILTER';
export const RESET_TRIP_FILTERS = 'RESET_TRIP_FILTERS';
export const SET_TRIP_LOADING = 'SET_TRIP_LOADING';
export const ADD_TRIP_DETAILS = 'ADD_TRIP_DETAILS';
export const SHOW_TRIP_DETAILS = 'SHOW_TRIP_DETAILS';
export const RESET_PROVIDERS_FILTERS = 'RESET_PROVIDERS_FILTERS';

// Purchase
export const REQUEST_PURCHASE = 'REQUEST_PURCHASE';
export const RESET_PURCHASE = 'RESET_PURCHASE';
export const RECEIVE_PURCHASE = 'RECEIVE_PURCHASE';
export const SET_FAILED_STATE_PURCHASE = 'SET_FAILED_STATE_PURCHASE';
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const SET_BUS_CATEGORIES = 'SET_BUS_CATEGORIES';
export const SET_SEAT_MAP = 'SET_SEAT_MAP';
export const PURCHASE_POST_PASSENGERS = 'PURCHASE_POST_PASSENGERS';
export const PURCHASE_SET_PASSENGERS = 'PURCHASE_SET_PASSENGERS';
export const REQUEST_TRIPS_DETAILS = 'REQUEST_TRIPS_DETAILS';
export const RECEIVE_TRIPS_DETAILS = 'RECEIVE_TRIPS_DETAILS';
export const SELECT_SEATS = 'SELECT_SEATS';
export const UPDATE_SEAT = 'UPDATE_SEAT';
export const REQUEST_TICKETS = 'REQUEST_TICKETS';
export const RECEIVE_TICKETS = 'RECEIVE_TICKETS';
export const CLEAR_TICKETS_ERROR_TYPE = 'CLEAR_TICKETS_ERROR_TYPE';
export const TOGGLE_INSURANCE = 'TOGGLE_INSURANCE';
export const REQUEST_DISCOUNT = 'REQUEST_DISCOUNT';
export const RECEIVE_DISCOUNT = 'RECEIVE_DISCOUNT';
export const UPDATE_USING_WALLET = 'UPDATE_USING_WALLET';
export const PURCHASE_SET_PAYMENT_CARDS = 'PURCHASE_SET_PAYMENT_CARDS';
export const PURCHASE_SET_PAYMENT_PLANS = 'PURCHASE_SET_PAYMENT_PLANS';
export const SELECT_PAYMENT_OPTION = 'SELECT_PAYMENT_OPTION';
export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const SELECT_MONTHLY_PLAN = 'SELECT_MONTHLY_PLAN';
export const EXPIRE_PURCHASE = 'EXPIRE_PURCHASE';
export const PURCHASE_SET_EXPIRATION = 'PURCHASE_SET_EXPIRATION';
export const SET_EMAIL_STATUS = 'SET_EMAIL_STATUS';
export const PURCHASE_INSURANCES_CHECKED = 'PURCHASE_INSURANCES_CHECKED';
export const FINISHED_SEAT_SELECTION = 'FINISHED_SEAT_SELECTION';
export const SET_INSTALLMENTS_CARD = 'SET_INSTALLMENTS_CARD';
export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';
export const TOGGLE_CARBON_OFFSET = 'TOGGLE_CARBON_OFFSET';
export const PURCHASE_EXTENDED_EXPIRATION = 'PURCHASE_EXTENDED_EXPIRATION';
export const RECEIVE_WALLET_TYPE = 'RECEIVE_WALLET_TYPE';
export const UPDATING_WALLET_TYPE = 'UPDATING_WALLET_TYPE';
export const VALIDATING_TAXPAYER_ID = 'VALIDATING_TAXPAYER_ID';

// Payment
export const RESET_PAYMENT_CARD_ERROR = 'RESET_PAYMENT_CARD_ERROR';
export const REQUEST_PAYMENT = 'REQUEST_PAYMENT';
export const RECEIVE_PAYMENT = 'RECEIVE_PAYMENT';
export const SET_CARD_ERROR_OCCURED = 'SET_CARD_ERROR_OCCURED';
export const SET_CYBERSOURCE_KEYS = 'SET_CYBERSOURCE_KEYS';
export const RESET_PAYMENT = 'RESET_PAYMENT';
export const SET_YUNO_SESSION_DATA = 'SET_YUNO_SESSION_DATA';

// Agencies
export const AGENCY_SET_SESSION = 'AGENCY_SET_SESSION';
export const AGENCY_SET_LOADING = 'AGENCY_SET_LOADING';

/* SIEMPREPLUS ACTIONS */
export const UPDATE_SIEMPRE_PLUS_DATA = 'UPDATE_SIEMPRE_PLUS_DATA';
export const SET_UPDATED_SIEMPRE_PLUS = 'SET_UPDATED_SIEMPRE_PLUS';

// Ads
export const SET_AD_IS_LOADING = 'SET_IS_LOADING_ADS';
export const SET_AD_DATA = 'SET_AD_DATA';
export const SET_AD_HAS_ERROR = 'SET_AD_HAS_ERROR';

// exchange
export const EXCHANGE_TYPEAHEAD_CHANGE = 'EXCHANGE_TYPEAHEAD_CHANGE';
export const EXCHANGE_TYPEAHEAD_CLEAR = 'EXCHANGE_TYPEAHEAD_CLEAR';
export const EXCHANGE_FETCH_LOCATIONS = 'EXCHANGE_FETCH_LOCATIONS';
export const EXCHANGE_DATEPICKER_CHANGE = 'EXCHANGE_DATEPICKER_CHANGE';
export const SET_EXCHANGE_OPERATION = 'SET_EXCHANGE_OPERATION';
export const EXCHANGE_SET_LOADING = 'EXCHANGE_SET_LOADING';
export const EXCHANGE_RECEIVE = 'EXCHANGE_RECEIVE';
export const EXCHANGE_RESET = 'EXCHANGE_RESET';
export const ERROR_TRIP_EXCHANGE = 'ERROR_TRIP_EXCHANGE';

// Cards
export const SET_USER_CARDS = 'SET_USER_CARDS';
export const SET_USER_CARDS_LOADING = 'SET_USER_CARDS_LOADING';
export const SET_USER_SELECTED_CARD = 'SET_USER_SELECTED_CARD';
export const SET_CARDS_UPDATED = 'SET_CARDS_UPDATED';
export const SET_CARDS_LOADING = 'SET_CARDS_LOADING';
export const SET_CARDS_AVAILABLE = 'SET_CARDS_AVAILABLE';
export const SET_CARDS_ANOTHER_CARD = 'SET_CARDS_ANOTHER_CARD';
export const SET_SAVE_CARD = 'SET_SAVE_CARD';
export const RESET_CARDS_STATE = 'RESET_CARDS_STATE';

// Costapass
export const COSTAPASS_RECEIVE_PROFILE = 'COSTAPASS_RECEIVE_PROFILE';
export const COSTAPASS_ERROR = 'COSTAPASS_ERROR';
export const COSTAPASS_UPDATE_BALANCE = 'COSTAPASS_UPDATE_BALANCE';

// SSO
export const SSO_ERROR = 'SSO_ERROR';

// TICKETS
export const LOADING_TICKETS = 'LOADING_TICKETS';
export const SET_TICKETS = 'SET_TICKETS';

// OTP
export const LOADING_OTP = 'LOADING_OTP';
export const OTP_CONFIRMATION = 'OTP_CONFIRMATION';
export const OTP_CREATION = 'OTP_CREATION';
export const OTP_ERROR = 'OTP_ERROR';
export const OTP_CLEAR = 'OTP_CLEAR';

// LOYALTY
export const SET_POINTS_USED = 'SET_POINTS_USED';

// FLAT FARE
export const TOGGLE_FLAT_FARE = 'TOGGLE_FLAT_FARE';

// USER PREFERENCES
export const SET_RECOMMENDED_TRIPS = 'SET_RECOMMENDED_TRIPS';
export const SET_RECOMMENDED_TRIPS_LOADING = 'SET_RECOMMENDED_TRIPS_LOADING';

// SEATS BUS
export const RESET_SEAT_TRIPS = 'RESET_SEAT_TRIPS';
export const SET_SEAT_MAP_TRIPS = 'SET_SEAT_MAP_TRIPS';
export const SELECT_SEATS_TRIPS = 'SELECT_SEATS_TRIPS';
export const UPDATE_SEAT_TRIPS = 'UPDATE_SEAT_TRIPS';
export const SET_SEAT_MAP_ERROR = 'SET_SEAT_MAP_ERROR';
